@import url('https://fonts.googleapis.com/css2?family=Inter&family=Jost&family=Libre+Baskerville&family=PT+Serif:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter&family=Jost&family=PT+Serif:wght@700&display=swap');

body {
  margin: 0;
padding: 0;
box-sizing: border-box;
font-family: 'Jost', sans-serif !important;
}

:root {
  --navyBlue: #2b3a55;
  --white: #ffffff;
  --mainColor: rgb(206, 119, 119);
  --secondaryColor: rgb(232, 196, 196);
  --beigeColor: rgb(242, 229, 229);
}

/* Our Button */
.mainBtn{
  background-color: white !important;
  color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
  transition: all 0.7s ease !important;
  outline: auto !important;
}

.mainBtn:hover{
  color: white !important;
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

/* Our Input */
.mainInput, .mainInput:focus {
  background-color: var(--beigeColor);
  border-color: var(--beigeColor);
}

.mainInput:focus {
  box-shadow: none;
  border-color: var(--mainColor);
}

/* Our Checkbox */
.mainCheckbox:checked {
  background-color: var(--mainColor) !important;
  border-color: var(--mainColor) !important;
}

.mainCheckbox:focus {
  border-color: var(--beigeColor) !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.25rem var(--beigeColor) !important;
}

/* Our Title */
.mainTitle{
  color: var(--mainColor);
}

.blueHeader{
  color:var(--navyBlue);
  font-size: 38px;
  font-family: 'Libre Baskerville', serif;
  font-weight: bold;

}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  box-shadow: inset 0 0 0 100px transparent;
}
.darkBg{
  background-color: var(--navyBlue);
}

section{
  margin-top: 3rem !important;
}

.slick-prev:before, .slick-next:before{
color: var(--navyBlue)!important;
}
.slick-current{
  /* transform: scale(1.1); */
  transition: scale 2s ease-in-out ;
}

.Suggested .slick-current{
  /* transform: scale(1.03); */
  transition: scale 2s ease-in-out ;
}

.carousel-control-next-icon{
  background-image:   url('./assets/next.svg') !important;
}
.carousel-control-next{
  width: 6%;
  right: 20px;
  top: 70px;
}
.carousel-control-prev{
  width:6%;
  left: 20px;
  top: 70px;
}
.carousel-control-prev-icon{
  background-image: url('./assets/prev.svg') !important;
}

.dropdown-item:active {
  background-color: #fff!important;
}
.offcanvas{
  background-image: linear-gradient(-26deg, var(--beigeColor), white)!important;
}

.Toastify__toast-theme--colored.Toastify__toast--error {

  background: var(--mainColor)!important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: var(--navyBlue)!important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  background: #dea4a4!important;

}
.Toastify__toast-theme--colored.Toastify__toast--default {
  background: rgb(163, 125, 125) !important;
  /* background: #8dcd8d !important; */
  color :#fff !important;
}

@media screen and (min-width:300px) and (max-width:768px){
  .blueHeader{
    font-size: 26px;
    padding-bottom: 10px;
  }
}
@media screen and (min-width:769px) and (max-width:1200px){
  .blueHeader{
    font-size: 29px;
    padding-bottom: 10px;
  }
}

.css-6cwnna-MuiSlider-mark {
  background-color: var(--mainColor) !important;
}
@media screen and (max-width:990px) {
  .bookSize{
    justify-content: center;
  }
}

.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color:transparent !important;
  border-top: 1px solid transparent !important;
  border-left: 1px solid transparent !important;
  border-right: 1px solid transparent !important;
  border-radius: 7px 7px 0 0  !important;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color:transparent !important;
  border-top: 1px solid var(--secondaryColor) !important;
  border-left: 1px solid var(--secondaryColor) !important;
  border-right: 1px solid var(--secondaryColor) !important;
  border-radius: 7px 7px 0 0  !important;
}

.wFitContent{
  width: fit-content;
}

.cursorPointer {
  cursor: pointer;
}