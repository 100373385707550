@import url('https://fonts.googleapis.com/css2?family=Inter&family=Jost&family=PT+Serif:wght@700&display=swap');

.navBarContainer {
    display: flex;
    flex-direction: column;
}

.logo {
    width: 6%;
    margin-right: 60px;
}

.navLink, navLink:hover {
    font-family: 'Jost', sans-serif !important;
    font-size: 19px !important;
    font-weight: bold !important;
    color: #2b3069 !important;
    position: relative;
    text-transform: none !important;
    background-color: transparent !important;
}

.navLinkIcon {
    font-family: 'Inter', sans-serif !important;
    font-size: 19px !important;
    color: #FFF !important;
    position: relative;
}

.navLinkBorder::before {
    content: '';
    position: absolute;
    bottom: 6px;
    width: 0%;
    height: 2px;
    background: var(--navyBlue);
    transition: all 1s ease;

}

.navItem:hover>.navLinkBorder::before, .itemActive::before {
    width: 82%;
}

.transparent {
    background-color: transparent;
    transition: all 0.7s;
}

.size {

    font-size: 19px;
}

.dropdownMenu {
    border: 1px #dee2e6 solid !important;
    font-weight: 300!important;
    font-family: 'Jost', sans-serif;
    font-size: 16px !important;
}
.item{
    font-weight:300!important;
    font-family: 'Jost', sans-serif;
    font-size: 17px !important; 
}
.itemColor{
    color: var(--mainColor)!important;
    font-size: 18px !important; 
    font-weight:300 !important;
    text-decoration: underline;
}
.item:hover, .item.dropItemActive {
    background-color: var(--beigeColor) !important;

}
.dropItemActivetran,.dropItemActivetran:hover,.dropItemActivetran:focus,.dropItemActivetran:active{
    background-color: #fff!important;
}

.colorUser {
    color:#fff !important;
    font-size: 18px;
    margin-left: 5px;
}

.number {
    width: 21px;
    background: #fff;
    top: -3px;
    right: -29px;
    border-radius: 50%;
    height: 21px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.num {
    font-size: 13px;
    color: var(--mainColor);
    left: 6px;
}

.navTop {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    list-style: none;
    padding: 7px 15px 7px 0px;
    background:var(--navyBlue) !important;
}

.navTop li {
    list-style: none;
}

.dropdownMenu {
    background-image: white;
}

.colorNav {

    background-color: var(--beigeColor);
    transition: all 0.8s;
}
.badgeNumber{
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Media Quries NavBar */
@media screen and (min-width:100px) and (max-width: 600px) {
    .logo {
        width: 30%;
        margin-right: 0px;
    }
    .size{
        font-size: 14px;
    }
    .colorUser{
        font-size: 17px;
    }
    .number{
        width: 18px;
    /* background: var(--mainColor); */
    top: 5px;
    right: -26px;
    border-radius: 50%;
    height: 18px;
    position: absolute;
    }
    .num{
        left: 5px;
    top: -1px;
    }
}

@media screen and (min-width:601px) and (max-width: 992px) {
    .logo {
        width: 15%;
        margin-right: 0px;
    }

}

@media screen and (min-width:993px) and (max-width: 1300px) {
    .logo {
        width: 10%;
        margin-right: 15px;
    }
}

@media screen and (min-width:1301px) and (max-width: 1500px) {
    .logo {
        width: 9%;
    }

}

@media screen and (min-width:1501px) and (max-width: 1600px) {
    .logo {
        width: 9%;
    }
}

@media screen and (min-width:300px) and (max-width:991px) {
    .bgCollapse {
        background-image: linear-gradient(white, var(--beigeColor));

        border-radius: 20px;
        position: relative;
        top: 5px;
    }

    .bgCollapse .navLink {
        padding-left: 30px;
    }
    .navItem:hover .navLinkBorder::before, .itemActive::before{
        width: 0%;
    }
}

.tyrNumber
{
    margin-right: 20px;
    width: 20px;
    height: 20px;
    background-color: #FFF;
    color: var(--mainColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: 13px;
    text-align: center;
    line-height:1 !important;
}
