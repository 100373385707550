@import url('https://fonts.googleapis.com/css2?family=Inter&family=Jost&family=PT+Serif:wght@700&display=swap');

.footer {
    font-family: 'Jost', sans-serif;
    font-size: 18px;

}

.marginTop {
    /* margin-top: 6rem !important; */
}

.removeMargT{
    margin-top: 0 !important;
}

@media screen and (min-width:300px) and (max-width:666px) {
    .footer {
        font-size: 13px;
    }
}